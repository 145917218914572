import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control/Configure the size of the markers and lines in the chart
const BOUNDARY_SIZE = 2;
const DATA_POINT_SIZE = 3;

function VrsValidationScatterChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let textVal = value
      if(key === 'VRS' && value === "3")
        textVal = "VRS Event"
      else if(key === 'VRS' && value === "2")
        textVal = "Risk of VRS Event"
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${textVal}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    zoomType: "xy",
    animationEnabled: true,
    height: 500,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      titleFontFamily: dataContext.CHART_FONTS.fontFamily,
      titleFontSize: 15,
      titleFontWeight: "bold",
      title: "True Airspeed (kts)",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      titleFontFamily: dataContext.CHART_FONTS.fontFamily,
      titleFontSize: 15,
      titleFontWeight: "bold",
      title: "Vertical Speed (ft/min)"
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

      if (dataTarget) {
        // console.log("VRS Validation Scatter Chart Data Target:", dataTarget);

        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;
        
        const calculated_fields = dataTarget.calculated_fields;
        const clookup = calculated_fields.lookup;
        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data
        const { vrsColorMap } = dataContext.COLOR_MAPS.current;
        let vrs_polygon = []
        let vrs_polygon_2 = []
        if(dataTarget.flight_meta && dataTarget.flight_meta.data){
          // console.log(dataTarget.flight_meta.lookup["vrs_polygon"])
          // console.log( dataTarget.flight_meta.data[0])
          // console.log( dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_polygon"]])
          vrs_polygon = dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_polygon"]]
          vrs_polygon_2 = dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_polygon_2"]] 
        }
        else {
          const { aggregations: { vrs_polygon = [], vrs_polygon_2 = [] } = {} } =  dataTarget;
        }
        // Reformat static VRS data to conform to expected data structure
        // console.log("Using VRS static data as baseline:", vrs_polygon);
        let vrsStaticDataPoints = vrs_polygon.map(item => {
          let color = vrsColorMap["BOUNDARY"];
          let vrsStaticFieldsOfInterest = {
            "VX (kts)": dataContext.roundStr(item[0]),
            "VZ (ft/min)": dataContext.roundStr(item[1]),
          }

          let boundaryTooltipText = getFeaturedTooltip(vrsStaticFieldsOfInterest, color);

          return {
            x: dataContext.round(item[0]),
            y: dataContext.round(item[1]),
            color: color,
            tooltipText: boundaryTooltipText,
          };
        });

        // Reformat static VRS2 data to conform to expected data structure
        // console.log("Using VRS2 static data as baseline:", vrs_polygon);
        let vrsStaticDataPoints2 = vrs_polygon_2.map(item => {
          let color = vrsColorMap["BOUNDARY 2"];
          let vrsStaticFieldsOfInterest = {
            "VX (kts)": dataContext.roundStr(item[0]),
            "VZ (ft/min)": dataContext.roundStr(item[1]),
          }

          let boundaryTooltipText = getFeaturedTooltip(vrsStaticFieldsOfInterest, color);

          return {
            x: dataContext.round(item[0]),
            y: dataContext.round(item[1]),
            color: color,
            tooltipText: boundaryTooltipText,
          };
        });

        let vrsBoundaryDataSeries = {
          type: "area",
          lineThickness: BOUNDARY_SIZE,
          markerType: "none",
          name: "VRS Boundary",
          showInLegend: true,
          color: vrsColorMap["BOUNDARY"],
          legendMarkerColor: vrsColorMap["BOUNDARY"],
          fillOpacity: 0.2,
          legendMarkerType: "square",
          dataPoints: vrsStaticDataPoints
        };

        let vrsBoundaryDataSeries2 = {
          type: "area",
          lineThickness: BOUNDARY_SIZE,
          markerType: "none",
          name: "VRS Risk Boundary",
          showInLegend: true,
          color: vrsColorMap["BOUNDARY 2"],
          legendMarkerColor: vrsColorMap["BOUNDARY 2"],
          fillOpacity: 0.2,
          legendMarkerType: "square",
          dataPoints: vrsStaticDataPoints2
        };

        // Extract only required data key/values for rendering data points and tooltips from track points data
        // Separate into different scatter series by VRS value so legend can map colors correctly (e.g., No VRS, VRS, Unknown)
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        let vrsDataSeries = {
          type: "scatter",
          markerType: "square",
          lineThickness: DATA_POINT_SIZE,
          markerSize: DATA_POINT_SIZE,
          showInLegend: false,
          dataPoints: []
        };

        // NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
        let highRiskLegendPlaceholder = {
          legendText: "VRS Event",
          showInLegend: true,
          legendMarkerColor: vrsColorMap["HIGH RISK"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        let mediumRiskLegendPlaceholder = {
          legendText: "Risk of VRS Event",
          showInLegend: true,
          legendMarkerColor: vrsColorMap["HIGH RISK 2"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        let noRiskLegendPlaceholder = {
          legendText: "No VRS",
          showInLegend: true,
          legendMarkerColor: vrsColorMap["NO RISK"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        // Keep track of points where vrs is true so they can be added at the end and appear on top of other non-vrs points
        let vrsTruePoints = [];

        Array.from(data).forEach((point) => {
          let trueAirspeed = point[lookup.true_airspeed_final_kt];
          let verticalSpeed = point[lookup.verticalspeed_final_fpm];
          let vrs = point[lookup.vrs] || point[lookup.vrs_sibling];

          // Only add record if x and y values are not null (breaks otherwise)
          if (trueAirspeed !== null && verticalSpeed !== null) {
            let color = 
            vrs === true ? vrsColorMap["HIGH RISK"] :
            vrs === 3 ? vrsColorMap["HIGH RISK"] :
            vrs === 2 ? vrsColorMap["HIGH RISK 2"] :
            vrs === false ? vrsColorMap["NO RISK"] :
            vrs === 0 ? vrsColorMap["NO RISK"] :
                vrsColorMap["UNKNOWN RISK"];

            let fieldsOfInterest = {
              "Time": point[lookup.human_readable_datetime],
              "VRS": dataContext.capitalizeWords(vrs),
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "True Airspeed": point[lookup.true_airspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // console.log("VrsValidationScatterChart fields of interest:", fieldsOfInterest);

            let dataTooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            let dataPoint = {
              flightid: point[lookup.flightid],
              x: dataContext.round(trueAirspeed),
              y: dataContext.round(verticalSpeed),
              color: color,
              tooltipText: dataTooltipText,
              markerSize: vrs ? DATA_POINT_SIZE * 2 : DATA_POINT_SIZE,
            };

            if (vrs) {
              vrsTruePoints.push(dataPoint);
            } else {
              vrsDataSeries.dataPoints.push(dataPoint);
            }
          }

        });

        // Iterate through the vrs true data points and add them to the vrs data series
        Array.from(vrsTruePoints).forEach(point => {
          vrsDataSeries.dataPoints.push(point);
        });

        // Iterate through data points and set the line colors to the color of the next point
        Array.from(vrsDataSeries.dataPoints).forEach((currentPoint, idx) => {
          let nextPointExists = (idx + 1) <= vrsDataSeries.dataPoints.length - 1;
          let nextPoint = nextPointExists ? vrsDataSeries.dataPoints[idx + 1] : null;
          currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        });

        // console.log("VrsValidationScatterChart vrsDataSeries:", vrsDataSeries);

        setDataOfInterestExists(vrsDataSeries.dataPoints.length > 0);

        // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
        let vertSpeedMetrics = dataContext.getMinMaxValues(data, lookup.verticalspeed_final_fpm);
        let staticMetrics = dataContext.getMinMaxValues(vrs_polygon, 1); // 1 = "VZftmin"
        let staticMetrics2 = dataContext.getMinMaxValues(vrs_polygon_2, 1);

        // Choose the lowest min between vertical speed and the static data
        let min = Math.min(vertSpeedMetrics.min, staticMetrics.min, staticMetrics2.min);

        // Choose the highest max between vertical speed and the static data
        let max = Math.max(vertSpeedMetrics.max, staticMetrics.max, staticMetrics2.max);

        // Calculate the padding to create a gab between y axis boundary and the last data point in range
        let padding = (dataContext.getMagnitude(max) / 10) * 5;
        // console.log("Min and Max values for vrs validation:", [min, max]);
        // console.log("|-- Using padding for y-axis range:", padding);

        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;
         // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        setOptions({
          ...options,
          axisY: {
            ...options.axisY,
            minimum: min - padding,
            maximum: max + padding,
          },
          axisX: {
            ...options.axisX,
            title: calculated_fields && calculated_fields.data && clookup && calculated_fields.data[0][clookup['airspeed_calculated']] ? "Calculated True Airspeed (kts)" : "True Airspeed (kts)",
          },
          data: [
            vrsBoundaryDataSeries,
            vrsBoundaryDataSeries2,
            vrsDataSeries,
            highRiskLegendPlaceholder,
            mediumRiskLegendPlaceholder,
            noRiskLegendPlaceholder,
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  useEffect(() => {
    if (isMounted.current) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

VrsValidationScatterChart.propTypes = propTypes;
VrsValidationScatterChart.defaultProps = defaultProps;

export default VrsValidationScatterChart;
