import React, {useState, useEffect, useContext, useRef} from 'react'
import UserContext from '../components/UserContext'
import getDashboardById from '../services/getDashboardById'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import DashboardNav from '../components/DashboardNav'


const ScoreDashboard = (props) => {

    const [state, setState] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const containerDiv = useRef(null)
    // const [error, setError] = useState([])
    // const [flightId, setFlightId] = useState([])
    // const [lastLoadedId, setLastLoadedId] = useState();
    // const flight = useParams()
    // console.log({props})

    const userContext = useContext(UserContext)  

    useEffect(() => {
        // console.log({flight})
        // setFlightId(flight.id)
        
        // console.log(props.flight.flightmetadata__id)
        
        //dashboardName = ?
        getDashboardById(userContext.user, "ScoreDashboardID", true, true)
        .then((result) => {
            if ('EmbedUrl' in result) {
                setState(result.EmbedUrl)
                setIsLoaded(true)
            }
        })
        .catch((error) => {
          console.log(error)
            // setError(error)
        })
    }, [])

    // useEffect(() => {
        
    // }, [propFlight])

    const getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("In embed method")
        // setContainerDiv(document.getElementById("dashboardContainer"));
        if (containerDiv.current) {
            //clear any existing embeds so we can embed a new one and not duplicate
            while (containerDiv.current.lastChild) {
                // console.log("Remove a child comp")
                containerDiv.current.removeChild(containerDiv.current.lastChild)
            }

            const options = {
                url: embedUrl,
                container: containerDiv.current,
                parameters: {
                    //  flightid : props.flight? props.flight.flightmetadata__id : ''
                },
                scrolling: "no",
                // height: "700px",
                height: "AutoFit",
                width: "100%",
                footerPaddingEnabled: true,
            };


            QuickSightEmbedding.embedDashboard(options);
        }
        // console.log(dashboard)
    }

    if (isLoaded) {
        getQuicksightDashboardEmbedURL(state)
        // setLastLoadedId(propFlight.flightmetadata__id)
    }



    return (
        <>
            <DashboardNav />
            
            <div className="stretch_grid-container">
            <h1>Unstable Approach Scores</h1>
            <div>
                <div ref={containerDiv} id="dashboardContainer" className="embedContainerHeight"></div>
            </div>
            </div>
        </>
    )
}

export default ScoreDashboard
